import React from "react";

const Footer2 = () => {
  return (
    <div className="raleway bottom-0 left-0 w-full bg-red-900 text-white py-4 text-center font-sans xl:text-xl">
      <p>All Copyrights Reserved @In Your Corner</p>
    </div>
  );
};

export default Footer2;
